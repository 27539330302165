import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Password from "../Login/Password";
import axios from "axios";

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
  const [status, setStatus] = useState("Esperando Estado...");

  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
            <Navbar color="dark" dark expand="lg">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>

                   {user.menu_clientes  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoClientes">
                        CLIENTES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      CLIENTES
                    </NavLink>
                  )}

                  {user.menu_inventarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuInventarios">
                        INVENTARIO
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      INVENTARIO
                    </NavLink>
                  )}

                  

                  {user.menu_admin  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuAdmin">
                        ADMIN
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ADMIN
                    </NavLink>
                  )}

                  
                  {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuGastos">
                        GASTOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      GASTOS
                    </NavLink>
                  )}

                  {user.menu_socios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuRetiros">
                        RETIROS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      RETIROS
                    </NavLink>
                  )}

                  {user.menu_reportes  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                        REPORTES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      REPORTES
                    </NavLink>
                  )}


                  {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoUsuarios">
                        USUARIOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      USUARIOS
                    </NavLink>
                  )}
                  
                  {user.menu_logs  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuLogs">
                        LOGS
                      </NavLink>
                    </NavItem>
                  ) : undefined}

                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar>
          
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
