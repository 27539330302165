import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";

function ListadoPropiedaes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROPIEDADES = process.env.REACT_APP_URL_PROPIEDADES;
  const URL_PROPIETARIOS = process.env.REACT_APP_URL_PROPIETARIOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_TIPO_INMUEBLE = process.env.REACT_APP_URL_TIPO_INMUEBLE;
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;
  const URL_DELMEDIA = process.env.REACT_APP_URL_DELMEDIA;
 
  const [mailTo, setMailTo] = useState("");
  const [idEdit, setIdEdit] = useState("");

 const [nombre, setNombre] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const [moneda, setMoneda] = useState("");
  const [formaAvaluo, setFormaAvaluo] = useState("");
  const [avaluo, setAvaluo] = useState(0);
  const [importeAprox, setImporteAprox] = useState(0);
  const [totalParicipacion, setTotalParicipacion] = useState(0);

  const [socios, setSocios] = useState([]);
  const [tipoInmuebles, setTipoInmuebles] = useState([]);
  const [selectedTipoInmueble, setSelectedTipoInmueble] = useState("");

  const [modalEditSocios, setModalEditSocios] = useState(false);
  const toggleEditSocios = () => setModalEditSocios(!modalEditSocios);
  const [nombrePropiedad, setNombrePropiedad] = useState("");
  const [sociosPropiedad, setSociosPropiedad] = useState([]);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState([]);
  const [idContrato, setIdContrato] = useState("");
  const [modalContrato, setModalContrato] = useState(false);
  const toggleContrato = () => setModalContrato(!modalContrato);
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);
  

  const [inputFields, setInputFields] = useState([{
    id: uuidv4(),
    socios: "",
    participacion: 0
  }]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

 
  useMemo(() => {
    axios
      .get(URL_PROPIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPropiedades = response.data;
        // Array para pagination
        let arrayTabla = allPropiedades
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                nombre: a.nombre,
                calle: a.calle,
                numero_ext: a.numero_ext,
                numero_int: a.numero_int,
                delegacion: a.delegacion,
                estado: a.estado,
                pais: a.pais,
                cp: a.cp,
                colonia: a.colonia,
                ciudad: a.ciudad,
                observaciones: a.observaciones,
                idTipoInmueble: a.tipoInmueble[0]._id,
                tipoInmueble: a.tipoInmueble[0].name,
                avaluo: a.avaluo,
                formaAvaluo: a.formaAvaluo,
                importeAprox: a.importeAprox,
                moneda: a.moneda,
                rentado: a.rentado,
                importeRenta: a.importeRenta,
                idCliente: a.clientes && a.clientes.length > 0 ? a.clientes[0]._id : "",
                cliente: a.clientes && a.clientes.length > 0 ? a.clientes[0].nombre_comercial : ""
              }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_SOCIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSocios = response.data

        setSocios(allSocios);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_TIPO_INMUEBLE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTipoInmuebles = response.data

        setTipoInmuebles(allTipoInmuebles);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre,
          a.telefono,
          a.email,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Propiedades`, 15, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Propiedades.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          id: a._id,
          Nombre: a.nombre,
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPropiedades";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPropiedades",
        sheetFilter: ["id", "Nombre"],
        sheetHeader: ["id", "Nombre"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre,
          a.telefono,
          a.email,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Propiedades`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Propiedades",
          email: mailTo,
          fileName: "ListadoPropiedades.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Propiedades.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    idEdit,
    nombre,
    calle,
    numero_ext,
    numero_int,
    delegacion,
    estado,
    pais,
    cp,
    colonia,
    ciudad,
    observaciones,
    idTipoInmueble,
    avaluo,
    formaAvaluo,
    importeAprox,
    moneda
  ) {
    setIdEdit(idEdit)
    setNombre(nombre)
    setCalle(calle)
    setNumeroExt(numero_ext)
    setNumeroInt(numero_int)
    setDelegacion(delegacion)
    setEstado(estado)
    setPais(pais)
    setCP(cp)
    setColonia(colonia)
    setCiudad(ciudad)
    setObservaciones(observaciones)
    setSelectedTipoInmueble(idTipoInmueble)
    setAvaluo(avaluo)
    setFormaAvaluo(formaAvaluo)
    setImporteAprox(importeAprox)
    setMoneda(moneda)
    toggleEdit();
  }

  

  function editPropiedades(event) {
    event.preventDefault();
    const URL_PROPIEDADES_EDIT = `${process.env.REACT_APP_URL_PROPIEDADES}/${idEdit}`;
        axios.patch(
          URL_PROPIEDADES_EDIT,
          {
            nombre,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            observaciones,
            avaluo,
            formaAvaluo,
            importeAprox
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Propiedad",
            detalle: `${nombre}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Nombre ", field: "nombre", sortable: true },
    { name: "Tipo", field: "tipoInmueble", sortable: true },
    { name: "Rentado", field: "rentado", sortable: true },
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Importe Renta", field: "importeRenta", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.tipoInmueble.toLowerCase().includes(search.toLowerCase())
          );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo));
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importeRenta" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importeRenta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importeRenta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

  const sociosNoEnPropiedad = socios.filter(
    (socio) => !sociosPropiedad.some((sp) => sp.socios === socio._id)
  );

  function EditSocios(idProp, nombre) {
    setInputFields([
      {
        id: uuidv4(),
        socios: "",
        participacion: 0,
      },
    ]);
    setIdEdit(idProp);
    setNombrePropiedad(nombre);
    axios
      .get(`${URL_PROPIETARIOS}Propiedad/${idProp}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSociosOrden = response.data;
        let arrayTabla = allSociosOrden
          .map((a) => {
            return {
              id: a._id,
              socios: a.socios[0]._id,
              nombreSocio: a.socios[0].name,
              participacion: a.participacion,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setSociosPropiedad(dataFinal);

      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditSocios();
  }

  const handleChangeInputExistentesParticipacion = (id, event) => {
    const { value } = event.target;
    setSociosPropiedad((prevSocios) =>
      prevSocios.map((socio) =>
        socio.id === id ? { ...socio, participacion: parseFloat(value) } : socio
      )
    );
  };

  function BuscaSocio(id, event) {
    socios.map((a) => {
      if (a._id == event.target.value) {
        let idSocio = a._id;
        handleChangeInputSocio(id, idSocio);
      }
    });
  }
  const handleChangeInputSocio = (id, idSocio) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.socios = idSocio;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.participacion = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        socios: "",
        participacion: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };



  const savePropiedad = async (event) => {
    event.preventDefault();

     // Calcular la suma total de las participaciones
  const totalParticipacion = 
  inputFields.reduce((acc, field) => acc + parseFloat(field.participacion), 0) +
  sociosPropiedad.reduce((acc, socio) => acc + parseFloat(socio.participacion), 0);

// Verificar si la suma es 100
if (totalParticipacion !== 100) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "La suma de las participaciones debe ser 100%. Por favor, verifica los valores ingresados.",
  })
  return;
}

    toggleProgreso();

    let totalSocios = inputFields.length + sociosPropiedad.length;
    try {
          inputFields.map((a) => {
            if (a.socios != "" && a.participacion > 0) {
              axios
                .post(
                  URL_PROPIETARIOS,
                  {
                    propiedades: idEdit,
                    socios: a.socios,
                    participacion: a.participacion,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalSocios = totalSocios - 1;
                  if (totalSocios == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Editar Propietarios",
                          detalle: `${nombrePropiedad}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              // cerrar if
            } else {
              totalSocios = totalSocios - 1;
              if (totalSocios == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Propietarios",
                      detalle: `${nombrePropiedad}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });

          sociosPropiedad.map((a) => {
            axios
              .patch(
                `${URL_PROPIETARIOS}/${a.id}`,
                {
                  participacion: a.participacion,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalSocios = totalSocios - 1;
                if (totalSocios == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Propietarios",
                        detalle: `${nombrePropiedad}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function saveContrato() {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("contrato", idContrato);

    axios.post(URL_UPMEDIA, formData, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then((response) => {

      axios.patch(
        `${URL_PROPIEDADES}/${idContrato}`,
        {
          archivo: "Si",
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )


      .then((response) => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Contrato",
            detalle: `Subir Archivo: ${file.name}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

        .then((response) => {


        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    setFile();
    setPhoto([]);
  }

  function borrarFoto(id) {

    axios.post(`${URL_DELMEDIA}/${id}`, {}, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then(() => {

      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    // setFile();
    // setPhoto([]);
  }

  function jalaFoto(idCont) {
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContrato(idCont);


    toggleFoto();
  }
  
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_inventarios ? (
        <div className="card container col-sm-11">
          <Row>
            <Col md={8}>
              {/* {user.propiedades_create ? ( */}
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/PropiedadesCreate"
                >
                  Nueva Propiedad
                </Button>
                <Button
                  size="sm"
              href="/MenuInventarios"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Propiedades</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Propiedades</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td> {p.nombre}</td>
                      <td> {p.tipoInmueble}</td>
                      <td> {p.rentado}</td>
                      <td> {p.cliente}</td>
                      <td> {new Intl.NumberFormat( "es-MX", { style: "currency", currency: "MXN" }).format(p.importeRenta)}</td>
                      <td>
                        {/* {user.propiedades_create ? ( */}
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  p._id,
                                  p.nombre,
                                  p.calle,
                                  p.numero_ext,
                                  p.numero_int,
                                  p.delegacion,
                                  p.estado,
                                  p.pais,
                                  p.cp,
                                  p.colonia,
                                  p.ciudad,
                                  p.observaciones,
                                  p.idTipoInmueble,
                                  p.avaluo,
                                  p.formaAvaluo,
                                  p.importeAprox,
                                  p.moneda,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaFoto(p._id)}
                              >
                                <i class="fas fa-camera"></i>
                              </Button>
                            <Button
                                color="danger"
                                size="sm"
                                onClick={(e) => {
                                  setIdContrato(p._id);
                                  toggleContrato();
                                }}
                              >
                                <i class="fas fa-file"></i>
                              </Button>

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditSocios(p._id, p.nombre)}
                            >
                              <i class="fas fa-percent"></i>
                            </Button>
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_PROPIEDADES}
                            />
                          </div>
                       
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Propiedad {nombre}</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
             
              <Col md={3}>
                <Label>Nombre </Label>
                <Input
                  type="text"
                  placeholder="Nombre "
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Tipo</Label>
                <Input
                  type="select"
                  value={selectedTipoInmueble}
                  required
                  onChange={(e) => {
                    setSelectedTipoInmueble(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {tipoInmuebles.map((a) => (
                    <option value={a._id}>
                      {a.name}
                    </option>
                  ))}
                </Input>
              </Col>
            
            </Row>

            <Row>
              <Col md={6}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
             
              <Col md={3}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
           
              
              <Col md={9}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Avaluo</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={avaluo}
                className={`form-control`}
                onValueChange={(value) => {
                  setAvaluo(value);
                }}
              />
              </Col>
              <Col>
                <Label>Forma de Avaluo</Label>
                <Input
                  type="text"
                  placeholder="Forma de Avaluo"
                  value={formaAvaluo}
                  required
                  onChange={(e) => {
                    setFormaAvaluo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Renta Aproximada</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importeAprox}
                className={`form-control`}
                onValueChange={(value) => {
                  setImporteAprox(value);
                }}
              />
              </Col>

              <Col md={3}>
                <Label>Moneda</Label>
                <Input
                  type="select"
                  placeholder="Moneda"
                  value={moneda}
                  required
                  onChange={(e) => {
                    setMoneda(e.target.value);
                  }}
                >
                  <option value="MXN">MXN</option>
                  <option value="USD">USD</option>
                </Input>
              </Col>
             
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPropiedades}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalContrato} toggle={toggleContrato}>
            <ModalHeader toggle={toggleContrato}>
              <h4>Subir Archivos</h4>
            </ModalHeader>
            <ModalBody>
            <Col md={12}>
              <Label>
                Archivo
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>  
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={saveContrato}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal size="xxl" isOpen={modalFoto} toggle={toggleFoto}>
      <ModalHeader toggle={toggleFoto}>
        <h4>Archivos</h4>
      </ModalHeader>
      <ModalBody>
        <Row>
          {photo.length > 0 ? (
            photo.map((item) => (
              <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
                {item.format === "pdf" ? (
                  <embed
                    src={item.secure_url}
                    type="application/pdf"
                    width="500"
                    height="600"
                  />
                ) : (
                  <img
                    src={item.secure_url}
                    alt="Uploaded Image"
                    width="250"
                    height="auto"
                  />
                )}
                <div>
                  <Button
                    color="danger"
                    onClick={() => borrarFoto(item.asset_id)}
                    style={{ marginTop: "10px" }}
                  >
                    Eliminar
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <h4>No se ha subido una Foto o PDF....</h4>
          )}
        </Row>
      </ModalBody>
    </Modal>

      <Modal
        size="xl"
        isOpen={modalEditSocios}
        toggle={toggleEditSocios}
      >
        <ModalHeader toggle={toggleEditSocios}>
          <h4>Editar Socios {nombrePropiedad}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={7}>
              <Label className="mr-sm-2">Socio</Label>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Participacion</Label>
            </Col>
          </Row>

          {sociosPropiedad
          .sort((a, b) => (a.nombreSocio > b.nombreSocio ? 1 : -1))
          .map((ao) => (
            <div key={ao.id}>
              <Row>
                <Col md={7}>
                  <Input
                    // bsSize="sm"
                    name="socio"
                    type="text"
                    value={ao.nombreSocio}
                    required
                    disabled
                  />
                </Col>

                <Col md={3}>
                  <Input
                    // bsSize="sm"
                    name="Participacion"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Participacion"
                    value={ao.participacion}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesParticipacion(ao.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

          {/* Agregar mas socios */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={7}>
                  <Input
                    // bsSize="sm"
                    name="socios"
                    type="select"
                    value={inputField.socios}
                    required
                    onChange={(event) => {
                      BuscaSocio(inputField.id, event);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {sociosNoEnPropiedad
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={3}>
                  <Input
                    // bsSize="sm"
                    name="Participacion"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Participacion"
                    value={inputField.participacion}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}

          <Row>
            {/* <Col md={9}>
              <h4 id="logoutBoton">
                TOTAL {totalParicipacion} %
              </h4>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" 
          onClick={savePropiedad}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPropiedaes;
