import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CurrencyInput from "react-currency-input-field";

function RentasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_RENTAS = process.env.REACT_APP_URL_RENTAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PROPIEDADES = process.env.REACT_APP_URL_PROPIEDADES;
  const [text, setText] = useState(false);

  const [cliente, setCliente] = useState([]);
  const [propiedades, setPropiedades] = useState([]);
  const [selectedPropiedad, setSelectedPropiedad] = useState("");
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [frecuencia, setFrecuencia] = useState("");
  const [proximoCargo, setProximoCargo] = useState("");
  const [descripcion, setDescripcion] = useState("Renta");
  const [importe_actual, setImporteActual] = useState(0);
  const [importeKash, setImporteKash] = useState(0);
  const [importeTar, setImporteTar] = useState(0);
  const [tipo, setTipo] = useState("NA");
  const [moneda, setMoneda] = useState("MXN");


  const [value, setValue] = useState('');
  const [validaBoton, setValidaBoton] = useState(true);



  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setCliente(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PROPIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPropiedades = res.data;
        setPropiedades(allPropiedades);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const saveRenta = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_RENTAS,
        {
          fecha_inicio,
          fecha_fin,
          frecuencia,
          descripcion,
          importe_actual,
          importeKash,
          importeTar,
          importe_anterior:0,
          ultimo_aumento:fecha_inicio,
          clientes: value._id,
          propiedades: selectedPropiedad,
          proximoCargo,
          tipo: "NA",
          moneda
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Renta',
            detalle: `${value._id}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  };

  const options = cliente.map((option) => {
    const junta = option.nombre_comercial
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option, junta
    };
  })
  const handleFechaInicioChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setFechaInicio(e.target.value);

    // Calcular el primer día del siguiente mes
    const nextMonthFirstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
    const formattedNextMonthFirstDay = nextMonthFirstDay.toISOString().split('T')[0]; // Formato YYYY-MM-DD

    // Actualizar el estado de fecha_proximo_cargo
    setProximoCargo(formattedNextMonthFirstDay);
  };

  return (
    <>
      <Header />
      <br />
      {user.usuarios_permisos  ?(
      <div className="container">
        <div className="card container col-sm-8">
          <h3 align="center">Crea una Renta</h3>
          <Form onSubmit={saveRenta}>
            <Row>
              <Col md={6}>
                <Label>Propiedad</Label>
                <Input
                  type="select"
                  placeholder="Propiedad"
                  value={selectedPropiedad}
                  required
                  onChange={(e) => {
                    setSelectedPropiedad(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {propiedades.map((prop) => (
                    <option value={prop._id}>
                      {prop.nombre}
                    </option>
                  ))}
                </Input>
              </Col>
            <Col md={6}>
            <Label className="mr-sm-2">Cliente</Label>
            <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
            </Col>
            </Row>
          <Row>
            <Col md={6}>
            <Label for="exampleEmail" className="mr-sm-12">
                    Frecuencia
                  </Label>
                  <Input
                    type="select"
                    placeholder="Frecuencia"
                    value={frecuencia}
                    required
                    onChange={(e) => {
                      setFrecuencia(e.target.value);
                    }}
                  >
                    <option value="0">Frecuencia</option>
                    <option value="Mensual">Mensual</option>
                    <option value="Bimestral">Bimestral</option>
                    <option value="Trimestral">Trimestral</option>
                    <option value="Semestral">Semestral</option>
                    <option value="Anual">Anual</option>
                  </Input>
            </Col>

            <Col md={6}>
                <Label>Moneda</Label>
                <Input
                  type="select"
                  placeholder="Moneda"
                  value={moneda}
                  required
                  onChange={(e) => {
                    setMoneda(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="USD">USD</option>
                  <option value="MXN">MXN</option>
                </Input>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Label>Importe K</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importeKash}
                className={`form-control`}
                onValueChange={(value) => {
                  setImporteKash(value);
                  setImporteActual(parseFloat(value) + parseFloat(importeTar));
                }}
              />
              </Col>
            <Col md={6}>
                <Label>Importe T</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importeTar}
                className={`form-control`}
                onValueChange={(value) => {
                  setImporteTar(value);
                  setImporteActual(parseFloat(value) + parseFloat(importeKash));
                }}
              />
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Label>Importe</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importe_actual}
                className={`form-control`}
                disabled
              />
              </Col>
              {/* <Col md={6}>
                <Label>Tipo</Label>
                <Input
                  type="text"
                  placeholder="Tipo"
                  value={tipo}
                  required
                  onChange={(e) => {
                    setTipo(e.target.value);
                  }}
                  >
                    <option value="">Selecciona</option>
                    <option value="K">K</option>
                    <option value="T">T</option>
                  </Input>
              </Col>  */}
              </Row>
            <Row>
              <Col md={6}>
                <Label>Fecha Inicio</Label>
                <Input
                  type="date"
                  value={fecha_inicio}
                  required
                  onChange={handleFechaInicioChange}
                />
              </Col>
              <Col md={6}>
                <Label>Fecha Fin</Label>
                <Input
                  type="date"
                  value={fecha_fin}
                  required
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Label>Proximo Cargo</Label>
                <Input
                  type="date"
                  value={proximoCargo}
                  required
                  disabled
                />
              </Col>
            
              <Col md={6}>
            <Label className="mr-sm-2">Descripcion</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Descripcion"
              value={descripcion}
              required
              onChange={(e) => {
                setDescripcion(e.target.value);
              }}
            />
           </Col>
           </Row>
            <Row>
            {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Registrar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Registrar
      </Button>)}
              <div>
                <SweetAlert
                  show={text}
                  title="Creado con Exito"
                  // text="SweetAlert in React"
                  onConfirm={() => {
                    setText(false);
                  }}
                />
              </div>
              <Button
                // Editar Ruta
                href="/ListadoRentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
        </div>
      </div>
      ): undefined } 
    </>
  );
}

export default RentasCreate;
