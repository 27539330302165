import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function PorRetirarCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  // const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_POR_RETIRAR = process.env.REACT_APP_URL_POR_RETIRAR;
  const URL_POR_RETIRAR_SOCIOS = process.env.REACT_APP_URL_POR_RETIRAR_SOCIOS;
  const URL_PROPIEDADES = process.env.REACT_APP_URL_PROPIEDADES;
  const URL_PROPIETARIOS = process.env.REACT_APP_URL_PROPIETARIOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  // const [socios, setSocios] = useState([]);
  const [propiedades, setPropiedades] = useState([]);
  const [selectedPropiedad, setSelectedPropiedad] = useState("");
  const [propietarios, setPropietarios] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [ventasMXN, setVentasMXN] = useState(0);
  const [gastosMXN, setGastosMXN] = useState(0);
  const [repartidoMXN, setRepartidoMXN] = useState(0);
  const [utilidadMXN, setUtilidadMXN] = useState(0);
  const [ventasUSD, setVentasUSD] = useState(0);
  const [gastosUSD, setGastosUSD] = useState(0);
  const [repartidoUSD, setRepartidoUSD] = useState(0);
  const [utilidadUSD, setUtilidadUSD] = useState(0);

  const [observaciones, setObservaciones] = useState("NA");

  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);
  const [moneda, setMoneda] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    // axios
    //   .get(URL_SOCIOS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allSocios = response.data;
    //     let array = allSocios
    //       .map((a) => {
    //         return {
    //           _id: a._id,
    //           name: a.name,
    //           participacion: a.participacion,
    //           importe: 0,
    //         };
    //       })
    //       .filter(function (el) {
    //         return el != null;
    //       });

    //     let data = Object.values(array);

    //     setSocios(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(URL_PROPIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPropiedades = response.data;
        let array = allPropiedades
          .map((a) => {
            return {
              _id: a._id,
              nombre: a.nombre,
              participacion: a.participacion,
              ventasMXN: a.ventasMXN,
              gastosMXN: a.gastosMXN,
              repartidoMXN: a.repartidoMXN,
              ventasUSD: a.ventasUSD,
              gastosUSD: a.gastosUSD,
              repartidoUSD: a.repartidoUSD,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(array);

        setPropiedades(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function saveRetiro() {
    if(total == 0){
      Swal.fire({
        title: "Error!",
        text: "Debes ingresar un importe",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el retiro",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Retirar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = propietarios.length;

        axios
          .post(
            URL_POR_RETIRAR,
            {
              fecha,
              total: total,
              observaciones,
              moneda,
              propiedades: selectedPropiedad,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((dataRetiro) => {
            propietarios.map((a) => {
              axios
                .post(
                  `${URL_POR_RETIRAR_SOCIOS}`,
                  {
                    fecha,
                    socios: a.idSocios,
                    porRetirar: dataRetiro.data._id,
                    importe: a.importe,
                    participacion: a.participacion,
                    moneda,
                    propiedades: selectedPropiedad,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                });

              //Termina el map
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              // footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function jalaTotal(e) {
    if (moneda == "MXN") {
      if (e <= utilidadMXN) {
        setTotal(e);

        const newPropietarios = propietarios.map((i) => {
          i.importe = (i.participacion * e) / 100;
          return i;
        });
        setPropietarios(newPropietarios);
     
      } else {
     
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El Retiro no puede ser mayor a la utilidad",
        });
     
        setTotal(0);

        const newPropietarios = propietarios.map((i) => {
          i.importe = 0;
          return i;
        });
        setPropietarios(newPropietarios);

      }
    } else if (moneda == "USD") {
      if (e <= utilidadUSD) {
        setTotal(e);

        const newPropietarios = propietarios.map((i) => {
          i.importe = (i.participacion * e) / 100;
          return i;
        });
        setPropietarios(newPropietarios);
      
      } else {
        
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El Retiro no puede ser mayor a la utilidad",
        });

        setTotal(0);

        const newPropietarios = propietarios.map((i) => {
          i.importe = 0;
          return i;
        });
        setPropietarios(newPropietarios);
      }
    }
  }

  function jalaPropiedad(e) {
    setSelectedPropiedad(e);

    propiedades.map((i) => {
      if (i._id == e) {
        setVentasMXN(i.ventasMXN);
        setVentasUSD(i.ventasUSD);
        setGastosMXN(i.gastosMXN);
        setGastosUSD(i.gastosUSD);
        setRepartidoMXN(i.repartidoMXN);
        setRepartidoUSD(i.repartidoUSD);
        setUtilidadMXN(i.ventasMXN - i.gastosMXN - i.repartidoMXN);
        setUtilidadUSD(i.ventasUSD - i.gastosUSD - i.repartidoUSD);
      }
    });

    axios
      .get(`${URL_PROPIETARIOS}Propiedad/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPropietarios = response.data;
        let array = allPropietarios
          .map((a) => {
            return {
              _id: a._id,
              idSocios: a.socios[0]._id,
              name: a.socios[0].name,
              participacion: a.participacion,
              importe: 0,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(array);
        setPropietarios(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_socios ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Retiro</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>

                <Col md={2}>
                  <Label>Propiedades</Label>

                  <Input
                    type="select"
                    value={selectedPropiedad}
                    required
                    onChange={(e) => {
                      jalaPropiedad(e.target.value);
                    }}
                  >
                    <option value="">Seleccione</option>
                    {propiedades
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => (
                        <option key={a._id} value={a._id}>
                          {a.nombre}
                        </option>
                      ))}
                  </Input>
                </Col>
                <Col md={2}>
                  <Label>Moneda</Label>
                  <Input
                    type="select"
                    value={moneda}
                    required
                    onChange={(e) => {
                      setMoneda(e.target.value);
                    }}
                  >
                    <option value="">Seleccione</option>
                    <option value="MXN">MXN</option>
                    <option value="USD">USD</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                {moneda !== "" && (
                  <>
                    {["MXN", "USD"].includes(moneda) && (
                      <>
                        <Col md={2}>
                          <Label>Renta</Label>
                          <Input
                            type="number"
                            value={moneda === "MXN" ? ventasMXN : ventasUSD}
                            required
                            disabled
                          />
                        </Col>
                        <Col md={2}>
                          <Label>Gastos</Label>
                          <Input
                            type="number"
                            value={moneda === "MXN" ? gastosMXN : gastosUSD}
                            required
                            disabled
                          />
                        </Col>
                        <Col md={2}>
                          <Label>Repartido</Label>
                          <Input
                            type="number"
                            value={
                              moneda === "MXN" ? repartidoMXN : repartidoUSD
                            }
                            required
                            disabled
                          />
                        </Col>
                        <Col md={2}>
                          <Label>Utilidad</Label>
                          <Input
                            type="number"
                            value={moneda === "MXN" ? utilidadMXN : utilidadUSD}
                            required
                            disabled
                          />
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
              <Row>
                <Col md={2}>
                  <Label>Total a Repartir</Label>
                  <Input
                    type="number"
                    value={total}
                    required
                    onChange={(e) => {
                      jalaTotal(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    required
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
              <Row>
                {/* Tabla CargosContenedor */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Socio</th>
                      <th>Participacion</th>
                      <th>Por Retirar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {propietarios.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <td>{a.name}</td>
                        <td>{a.participacion}%</td>
                        <td>
                          {"$" +
                            new Intl.NumberFormat("en-US").format(a.importe)}
                        </td>
                      </tr>
                      // </div>
                    ))}
                    <tr>
                      <td></td>
                      <td className="negrita">Total</td>
                      <td className="negrita">
                        {"$" + new Intl.NumberFormat("en-US").format(total)}
                      </td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla CargosContenedor */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={saveRetiro}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/PorRetirar"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              Estamos enviando los correos, este proceso puede tardar varios
              minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              <br />
              <div className="divPadre2">
                <div className="divHijo2">
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PorRetirarCreate;
