import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesInventarios() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_gastos ?(
      <div className="container">
        <br />
        <br />
        <Row >

        <Col md={4} align="center">
          {user.menu_gastos ?(
            <Button href="/TipoInmueble" className="botonesMenu" color="success">
              <i class="far fa-list-alt fa-7x"></i>
              <br />
              <br />
              Tipos de Inmueble
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-list-alt fa-7x"></i>
          <br />
          <br />
          Tipos de Inmueble
        </Button> }
          </Col>
          
          <Col md={4} align="center">
          {user.menu_gastos ?(
            <Button href="/Propiedades" className="botonesMenu" color="success">
              <i class="far fa-list-alt fa-7x"></i>
              <br />
              <br />
              Propiedades
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-list-alt fa-7x"></i>
          <br />
          <br />
          Propiedades
        </Button> }
          </Col>
          
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesInventarios;
