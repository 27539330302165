import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function CargosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PROPIEDADES = process.env.REACT_APP_URL_PROPIEDADES;
  const [text, setText] = useState(false);

  const [cliente, setCliente] = useState([]);
  const [propiedades, setPropiedades] = useState([]);
  const [fecha, setFecha] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [importe, setImporte] = useState("");
  const [selectedPropiedad, setSelectedPropiedad] = useState("");
  const [value, setValue] = useState('');
  const [validaBoton, setValidaBoton] = useState(true);
  const [moneda, setMoneda] = useState('MXN');

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setCliente(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROPIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPropiedades = res.data;
        setPropiedades(allPropiedades);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCargo = async (event) => {
    event.preventDefault();
    setValidaBoton(false)
    try {
      await axios
      .post(
        URL_CARGOS,
        {
          renta: "No",
          fecha,
          descripcion,
          importe,
          clientes: value._id,
          abonos: 0,
          saldo: importe,
          moneda,
          propiedades: selectedPropiedad
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )

      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Cargo',
            detalle: `${value._id}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true)
      })
    }catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };

  const options = cliente.map((option) => {
    const junta = option.nombre_comercial
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option, junta
    };
  })

  return (
    <>
      <Header />
      <br />
      {user.usuarios_permisos ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">Crea un Cargo</h3>
            <Form onSubmit={saveCargo}>
              <Row>
                <Col>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col>
                <Label>Propiedades</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={selectedPropiedad}
                    required
                    onChange={(e) => {
                      setSelectedPropiedad(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {propiedades.map((a) => (
                      <option
                        value={a._id}
                      >
                        {a.nombre}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Descripción</Label>
                  <Input
                    type="text"
                    placeholder="Descripción"
                    value={descripcion}
                    required
                    onChange={(e) => {
                      setDescripcion(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                
              </Row>
              <Row>
              <Col md={6}>
                  <Label>Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    placeholder="Importe"
                    value={importe}
                    required
                    onChange={(e) => {
                      setImporte(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Moneda</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={moneda}
                    required
                    onChange={(e) => {
                      setMoneda(e.target.value);
                    }}
                  >
                    <option value="MXN">MXN</option>
                    <option value="USD">USD</option>
                  </Input>
                </Col>
                </Row>
               
              <Row>
              {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Registrar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Registrar
      </Button>)}
                {/* <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div> */}
                <Button
                  href="/ListadoCargos"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default CargosCreate;
