import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function ListadoMovimientosBancos() {
  const { user } = useContext(AuthContext);
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  


  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  let inicio = new Date();
  inicio.setDate(inicio.getDate()-30);
  let inicio2 = moment(inicio).format("YYYY-MM-DD");


  const [selectedFechaInicio, setSelectedFechaInicio] = useState(inicio2);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [totalUSD, setTotalUSD] = useState(0);
  const [totalMXN, setTotalMXN] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [bancos, setBancos] = useState([]);

  const [total, setTotal] = useState(0);

  const [validaBoton, setValidaBoton] = useState(true);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
      axios
        .get(
          `${URL_MOVIMIENTOS_BANCOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allMovs = response.data;
          
          setComments(allMovs);

        })
        .catch((err) => {
          console.log(err);
        });

       

  }, [user, selectedFechaInicio, selectedFechaFin]);

  useEffect(() => {

    axios
    .get(URL_BANCOS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allBancos = response.data;
      setBancos(allBancos);
    })
    .catch((err) => {
      console.log(err);
    });

   

    }, []);


  function PDFTabla() {
    total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) 
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, roundingIncrement: 5}).format(c.importe);
        return [c.fecha, c.movimiento, c.observaciones, importePDF, c.moneda];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Detalle de Movimientos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Observaciones", "Importe", "Moneda"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, roundingIncrement: 5}).format(total)],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleMovimientos.pdf");
  }

  function excel() {
    const dataExcel = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) 
      ) {
        return {
          Fecha: c.fecha,
          Movimiento: c.movimiento,
          Observaciones: c.observaciones,
          Importe: c.importe,
          Moneda: c.moneda
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleMovimientos-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleMovimientos`,
        sheetFilter: ["Fecha", "Movimiento", "Observaciones", "Importe", "Moneda"],
        sheetHeader: ["Fecha", "Movimiento", "Observaciones", "Importe", "Moneda"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) 
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, roundingIncrement: 5}).format(c.importe);
        return [c.fecha, c.movimiento, c.observaciones, importePDF, c.moneda];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Detalle de Movimientos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Observaciones", "Importe", "Moneda"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, roundingIncrement: 5}).format(total)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Movimientos",
          email: mailTo,
          fileName: "ListaMovimientos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Movimientos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Movimiento", field: "movimiento", sortable: true },
    { name: "Banco", field: "banco", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
    { name: "Moneda", field: "moneda", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.movimiento.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.banco.toLowerCase().includes(search.toLowerCase()) ||
          comment.moneda.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe.toString().includes(search)
      );
    }

    setTotalItems(computedComments.length);

    let mxn = computedComments.filter((e) => e.moneda == "MXN");
    let totalMXN = mxn.map((c) => parseFloat(c.importe));
    let TP = totalMXN.reduce((t, total, index) => t + total, 0);
    setTotalMXN(TP);

    let usd = computedComments.filter((e) => e.moneda == "USD");
    let totalUSD = usd.map((c) => parseFloat(c.importe));
    let TPUSD = totalUSD.reduce((t, total, index) => t + total, 0);
    setTotalUSD(TPUSD);

    //Sorting comments
    if (sorting.field  && sorting.field != "importe" ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "importe")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "importe")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    totalMXN, 
    totalUSD
  ]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin  ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                  
                    <Button
                      size="sm"
                      href="/MenuAdmin"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <br />
                <h3 align="center">Movimientos Bancos</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={8} style={{ textAlign:"right", paddingRight:"50px"}}>
              <h4>
              TOTAL {"$" + new Intl.NumberFormat("en-US").format(totalUSD)} USD /  {"$" + new Intl.NumberFormat("en-US").format(totalMXN)} MXN
              </h4>
              </Col> 
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      return (
                        <tr>
                          <td>{c.fecha}</td>
                          <td>{c.movimiento}</td>
                          <td>{c.banco}</td>
                          <td>{c.observaciones}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, roundingIncrement: 5}).format(c.importe)} 
                          </td>
                          <td>{c.moneda}</td>
                         
                        </tr>
                      );
                    })}
                  
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoMovimientosBancos;
