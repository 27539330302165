import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";

function PropiedadesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROPIEDADES = process.env.REACT_APP_URL_PROPIEDADES;
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_PROPIETARIOS = process.env.REACT_APP_URL_PROPIETARIOS;
  const URL_TIPO_INMUEBLE = process.env.REACT_APP_URL_TIPO_INMUEBLE;

  const [nombre, setNombre] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");
  const [validaBoton, setValidaBoton] = useState(true);

  const [moneda, setMoneda] = useState("MXN");
  const [formaAvaluo, setFormaAvaluo] = useState("NA");
  const [avaluo, setAvaluo] = useState(0);
  const [importeAprox, setImporteAprox] = useState(0);
  const [totalParicipacion, setTotalParicipacion] = useState(0);

  const [socios, setSocios] = useState([]);
  const [tipoInmuebles, setTipoInmuebles] = useState([]);
  const [selectedTipoInmueble, setSelectedTipoInmueble] = useState("");

  const [inputFields, setInputFields] = useState([{
    id: uuidv4(),
    idSocio: "",
    participacion: 0
  }]);

  useMemo(() => {
    axios
      .get(URL_SOCIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSocios = response.data

        setSocios(allSocios);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_TIPO_INMUEBLE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTipoInmuebles = response.data

        setTipoInmuebles(allTipoInmuebles);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, []);



  const savePropiedades = async (event) => {
    event.preventDefault();
    setValidaBoton(false);

    if(totalParicipacion > 100){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La participacion no puede ser mayor a 100%",
      })
      return
    }

    let totIF = inputFields.length

    try {
      await axios
        .post(
          URL_PROPIEDADES,
          {
            rentado: "No",
            importeAprox,
            importeRenta: 0,
            moneda,
            avaluo,
            observaciones,
            tipoInmueble: selectedTipoInmueble,
            nombre,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            ventasMXN: 0,
            gastosMXN: 0,
            repartidoMXN: 0,
            ventasUSD: 0,
            gastosUSD: 0,
            repartidoUSD: 0,
            formaAvaluo,
            archivo: "No"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {

          inputFields.map((a) => {
            axios
              .post(
                URL_PROPIETARIOS,
                {
                  propiedades: data.data._id,
                  socios: a.idSocio,
                  participacion: a.participacion
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(() => {
                totIF = totIF - 1
                if(totIF == 0){
                Swal.fire("Good job!", "Actualizado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });

        
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);

    const total = newInputFields.reduce((acc, curr) => acc + parseFloat(curr.participacion), 0);

    setTotalParicipacion(total);
  };

  const handleAddFields = () => {

    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idSocio: "",
        participacion: 0,
      },
    ]);
  };


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_inventarios ? (
        <div className="card container">
          <h3 align="center">Nueva Propiedad</h3>
          <Form onSubmit={savePropiedades}>
            <Row>
             
              <Col md={3}>
                <Label>Nombre </Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Tipo</Label>
                <Input
                  type="select"
                  value={selectedTipoInmueble}
                  required
                  onChange={(e) => {
                    setSelectedTipoInmueble(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {tipoInmuebles.map((a) => (
                    <option value={a._id}>
                      {a.name}
                    </option>
                  ))}
                </Input>
              </Col>
            
            </Row>

            <Row>
              <Col md={6}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
             
              <Col md={3}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
           
              
              <Col md={9}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Avaluo</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={avaluo}
                className={`form-control`}
                onValueChange={(value) => {
                  setAvaluo(value);
                }}
              />
              </Col>
              <Col>
                <Label>Forma de Avaluo</Label>
                <Input
                  type="text"
                  placeholder="Forma de Avaluo"
                  value={formaAvaluo}
                  required
                  onChange={(e) => {
                    setFormaAvaluo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Renta Aproximada</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importeAprox}
                className={`form-control`}
                onValueChange={(value) => {
                  setImporteAprox(value);
                }}
              />
              </Col>

              <Col md={3}>
                <Label>Moneda</Label>
                <Input
                  type="select"
                  placeholder="Moneda"
                  value={moneda}
                  required
                  onChange={(e) => {
                    setMoneda(e.target.value);
                  }}
                >
                  <option value="MXN">MXN</option>
                  <option value="USD">USD</option>
                </Input>
              </Col>
             
            </Row>
            <br />
            <Row>
              <Col md={3}>
              <Label>Socio</Label>
              </Col>
              
              <Col md={3}>
              <Label>Paricipacion</Label>
              </Col>
              <Col md={3}>
              <Label>Paricipacion $</Label>
              </Col>
            </Row>

                    {
                      inputFields.map((inputField) => (
                        <div key={inputField.id}>
                          <Row>
                            <Col md={3}>
                              <Input
                                type="select"
                                name="idSocio"
                                value={inputField.idSocio}
                                required
                                onChange={(event) => handleChangeInput(inputField.id, event)}
                              >
                                <option value="">Selecciona</option>
                                {socios.map((a) => (
                                  <option value={a._id}>
                                    {a.name}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col md={3}>
                              <Input
                                type="number"
                                name="participacion"
                                value={inputField.participacion}
                                required
                                onChange={(event) => handleChangeInput(inputField.id, event)}
                              />
                            </Col>
                            <Col md={3}>
                              <Input
                                type="number"
                                name="participacion"
                                value={(importeAprox *inputField.participacion)/ 100}
                                required
                                disabled
                              />
                            </Col>
                            <Col>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            disabled={inputFields.length === 1}
                            onClick={() => handleRemoveFields(inputField.id)}
                            tabindex="-1"
                          >
                            <i class="fas fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={handleAddFields}
                            tabindex="-1"
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </Col>
                            </Row>
                            </div>
                      ))
                    }
                    <br />
                    <Row className="negrita">
                      <Col md={3}>
                      <Label>TOTAL</Label>
                      </Col>
                      <Col md={3}>
                      <Label>{totalParicipacion}%</Label>
                      </Col>
                    </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/Propiedades"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default PropiedadesCreate;
