import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../Baja";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";

function ListadoClientes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
 
  const [clientes, setClientes] = useState([]);
  const [mailTo, setMailTo] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("");
  const [RFC, setRFC] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [contacto, setContacto] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [emailViejo, setEmailViejo] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [ultimo_pago, setUltimoPago] = useState("");
  const [forma_pago, setFormaPago] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

 
  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                nombre_comercial: a.nombre_comercial,
                razon_social: a.razon_social,
                RFC: a.RFC,
                calle: a.calle,
                numero_ext: a.numero_ext,
                numero_int: a.numero_int,
                delegacion: a.delegacion,
                estado: a.estado,
                pais: a.pais,
                cp: a.cp,
                colonia: a.colonia,
                ciudad: a.ciudad,
                contacto: a.contacto,
                telefono: a.telefono,
                email: a.email,
                observaciones: a.observaciones,
              }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setClientes(allClientes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, [user]);

  const ListaClientes = clientes.sort((a, b) =>
    a.nombre_comercial > b.nombre_comercial ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaClientes.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          a.telefono,
          a.email,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Clientes`, 15, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Clientes.pdf`);
  }

  function excel() {
    const dataExcel = ListaClientes.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Nombre: a.nombre_comercial,
          Telefono: a.telefono,
          Mail: a.email,
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoClientes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoClientes",
        sheetFilter: ["Nombre", "Telefono", "Mail",],
        sheetHeader: ["Nombre", "Telefono", "Mail",],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaClientes.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          a.telefono,
          a.email,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Clientes`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Clientes",
          email: mailTo,
          fileName: "ListadoClientes.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    idEdit,
    nombre_comercial,
    razon_social,
    RFC,
    calle,
    numero_ext,
    numero_int,
    delegacion,
    estado,
    pais,
    cp,
    colonia,
    ciudad,
    contacto,
    telefono,
    email,
    observaciones,
  ) {
    setIdEdit(idEdit)
    setNombreComercial(nombre_comercial)
    setRazonSocial(razon_social)
    setRFC(RFC)
    setCalle(calle)
    setNumeroExt(numero_ext)
    setNumeroInt(numero_int)
    setDelegacion(delegacion)
    setEstado(estado)
    setPais(pais)
    setCP(cp)
    setColonia(colonia)
    setCiudad(ciudad)
    setContacto(contacto)
    setTelefono(telefono)
    setEmail(email)
    setObservaciones(observaciones)
    toggleEdit();
  }

  

  function editClientes(event) {
    event.preventDefault();
    const URL_CLIENTES_EDIT = `${process.env.REACT_APP_URL_CLIENTES}/${idEdit}`;
        axios.patch(
          URL_CLIENTES_EDIT,
          {
            nombre_comercial,
            razon_social,
            RFC,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            contacto,
            telefono,
            email,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Cliente",
            detalle: `${nombre_comercial}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Contacto", field: "contacto", sortable: true },
    { name: "Telefono", field: "telefono", sortable: false },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase()) ||
          comment.contacto.toLowerCase().includes(search.toLowerCase())
          );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo));
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

  
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_clientes ? (
        <div className="card container col-sm-11">
          <Row>
            <Col md={8}>
              {/* {user.clientes_create ? ( */}
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ClientesCreate"
                >
                  Nuevo Cliente
                </Button>
              {/* ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Cliente
                </Button>
              )} */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Clientes</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Clientes</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td> {p.nombre_comercial}</td>
                      <td>{p.contacto}</td>
                      <td>{p.telefono}</td>
                      <td>{p.email}</td>
                      <td>
                        {/* {user.clientes_create ? ( */}
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  p._id,
                                  p.nombre_comercial,
                                  p.razon_social,
                                  p.RFC,
                                  p.calle,
                                  p.numero_ext,
                                  p.numero_int,
                                  p.delegacion,
                                  p.estado,
                                  p.pais,
                                  p.cp,
                                  p.colonia,
                                  p.ciudad,
                                  p.contacto,
                                  p.telefono,
                                  p.email,
                                  p.observaciones,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            {/* <Button
                              color="success"
                              id="Password"
                              size="sm"
                              onClick={(e) => jalaInfoPassword(p._id)}
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button> */}
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_CLIENTES}
                            />
                          </div>
                        {/* ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Password"
                              size="sm"
                              disabled
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button>
                          </div>
                        )} */}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
       ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar cliente {nombre_comercial}</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              <Col md={3}>
                <Label>Contacto</Label>
                <Input
                  type="text"
                  placeholder="Contacto"
                  value={contacto}
                  required
                  onChange={(e) => {
                    setContacto(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre Comercial</Label>
                <Input
                  type="text"
                  placeholder="Nombre Comercial"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Razon Social</Label>
                <Input
                  type="text"
                  placeholder="Razon Social"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>RFC</Label>
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
             
              <Col md={3}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editClientes}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoClientes;
