import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesAdmin() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_admin ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/Bancos" className="botonesMenu" color="success">
              <i class="fas fa-university fa-7x"></i>
              <br />
              <br />
              Bancos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-university fa-7x"></i>
          <br />
          <br />
          Bancos
        </Button> }
          </Col>

       
          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/MovimientosBancos" className="botonesMenu" color="success">
              <i class="fas fa-list-ol fa-7x"></i>
              <br />
              <br />
              Movimientos Bancos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-ol fa-7x"></i>
          <br />
          <br />
          Movimientos Bancos
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoCargos" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Cargos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Cargos
        </Button> }
          </Col>

      


          </Row>
        <br />
          <Row>

          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoRentas" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Rentas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Rentas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoAbonos" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos
        </Button> }
          </Col>
          
          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/EstadoCuenta" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta
        </Button> }
          </Col>

       

          

          

          </Row>


      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesAdmin;
