import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

function EstadoCuenta() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");


  const [mailTo, setMailTo] = useState("");
  const [nombreCliente, setNombreCliente] = useState([]);
  const [saldoMXN, setSaldoMXN] = useState(0);
  const [saldoUSD, setSaldoUSD] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);



  const [activo, setActivo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [movimientos, setMovimientos] = useState([])
  const [movsMXN, setMovsMXN] = useState([])
  const [movsUSD, setMovsUSD] = useState([])
  const [saldoMXNInicial, setSaldoMXNInicial] = useState(0);
  const [saldoUSDInicial, setSaldoUSDInicial] = useState(0);
  


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;


  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                nombre: a.nombre_comercial,
                razon_social: a.razon_social,
                saldoMXN: parseFloat(a.saldoMXN),
                saldoUSD: parseFloat(a.saldoUSD),
                cliente:  a.nombre_comercial,
              }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
     
      let SIMXN = movsMXN.map((c)=>{
        if(selectedFechaInicio){
          if(
          (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)
          ){
            return (
                c.cargo - c.abono
            )
          }
        }
        else{
          return 0
        }
      }).filter(function (el) {
        return el != null;
      });

      let TCMXN = SIMXN.reduce((t, total, index) => t + total, 0);

      setSaldoMXNInicial(TCMXN)

      let SIUSD = movsUSD.map((c)=>{
        if(selectedFechaInicio){
          if(
          (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)
          ){
            return (
                c.cargo - c.abono
            )
          }
        }
        else{
          return 0
        }
      }).filter(function (el) {
        return el != null;
      });

      let TCUSD = SIUSD.reduce((t, total, index) => t + total, 0);

      setSaldoUSDInicial(TCUSD)

  }, [user, movimientos, selectedFechaInicio, movsMXN, movsUSD]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre,
          "$" + new Intl.NumberFormat("en-US").format(a.saldoMXN),
          "$" + new Intl.NumberFormat("en-US").format(a.saldoUSD),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [["Cliente", "Saldo MXN", "Saldo USD"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalSaldoMXN),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldoUSD),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EstadosDeCuenta.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Cliente: a.nombre,
          SaldoMXN: a.saldoMXN,
          SaldoUSD: a.saldoUSD,
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: ["Cliente", "SaldoMXN", "SaldoUSD"],
        sheetHeader: ["Cliente", "SaldoMXN", "SaldoUSD"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre,
          "$" + new Intl.NumberFormat("en-US").format(a.saldoMXN),
          "$" + new Intl.NumberFormat("en-US").format(a.saldoUSD),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [["Cliente", "Saldo MXN", "Saldo USD"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalSaldoMXN),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldoUSD),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras los saldos de los clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }



  const headers = [
    { name: "Nombre ", field: "nombre", sortable: true },
    { name: "Saldo MXN", field: "saldoMXN", sortable: true },
    { name: "Saldo USD", field: "saldoUSD", sortable: true },
    { name: "Estado Cuenta", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.saldoMXN.toString().includes(search) ||
          comment.saldoUSD.toString().includes(search) 
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

//Sorting comments
if (
  sorting.field &&
  sorting.field != "saldoMXN" &&
  sorting.field != "saldoUSD"
) {
  const reversed = sorting.order === "asc" ? 1 : -1;
  computedComments = computedComments.sort(
    (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  );
}

if (
  sorting.field &&
  sorting.order === "asc" &&
  (sorting.field == "saldoMXN" ||
    sorting.field == "saldoUSD")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
  );
}

if (
  sorting.field &&
  sorting.order === "desc" &&
  (sorting.field == "saldoMXN" ||
    sorting.field == "saldoUSD")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
  );
}

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

 async function jalaMov(id, cliente, saldoMXN, saldoUSD){
    await  axios
      .get(`${URL_ESTADO_CUENTA}/${id}`, {
        headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allVentas = response.data
      setMovimientos(allVentas);

      let tempMXN = allVentas.filter((e) => e.moneda == "MXN");
      setMovsMXN(tempMXN)
      let tempUSD = allVentas.filter((e) => e.moneda == "USD");
      setMovsUSD(tempUSD)

      //
    })
    .catch((err) => {
      console.log(err);
    });
      setNombreCliente(cliente)
      setSaldoMXN(saldoMXN)
      setSaldoUSD(saldoUSD)
      toggle()
  }


  // PDF Mail Excel Cliente

  function PDFOC() {
    let saldoMXNParcial = saldoMXNInicial
    let totalCargosMXNEdoCte = 0
    let totalAbonosMXNEdoCte = 0

    const dataMXN = movsMXN
      .map((a) => {
        if(
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)){
          totalCargosMXNEdoCte = totalCargosMXNEdoCte + a.cargo
          totalAbonosMXNEdoCte = totalAbonosMXNEdoCte + a.abono
          saldoMXNParcial = saldoMXNParcial + a.cargo - a.abono
      const cargosMXN = "$" + new Intl.NumberFormat("en-US").format(a.cargo)+ " " + a.moneda;
      const abonosMXN = "$" + new Intl.NumberFormat("en-US").format(a.abono)+ " " + a.moneda;
      var saldoMXNParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoMXNParcial)+ " " + a.moneda;
      return [a.fecha, a.movimiento, a.descripcion, cargosMXN, abonosMXN, saldoMXNParcialFormato];
    }
  })
    const dataMXNPDF = Object.values(dataMXN);
    const dataMXNPDFLimpia = dataMXNPDF.filter(function (el) {
      return el != null;
    });

    let saldoUSDParcial = saldoUSDInicial
    let totalCargosUSDEdoCte = 0
    let totalAbonosUSDEdoCte = 0

    const dataUSD = movsUSD
      .map((a) => {
        if(
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)){
          totalCargosUSDEdoCte = totalCargosUSDEdoCte + a.cargo
          totalAbonosUSDEdoCte = totalAbonosUSDEdoCte + a.abono
          saldoUSDParcial = saldoUSDParcial + a.cargo - a.abono
      const cargosUSD = "$" + new Intl.NumberFormat("en-US").format(a.cargo) + " " + a.moneda;
      const abonosUSD = "$" + new Intl.NumberFormat("en-US").format(a.abono)+ " " + a.moneda;
      var saldoUSDParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoUSDParcial)+ " " + a.moneda;
      return [a.fecha, a.movimiento, a.descripcion, cargosUSD, abonosUSD, saldoUSDParcialFormato];
    }
  })
    const dataUSDPDF = Object.values(dataUSD);
    const dataUSDPDFLimpia = dataUSDPDF.filter(function (el) {
      return el != null;
    });



    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";



    if(movsMXN.length>0){
      const docMXN = new jsPDF();
      docMXN.addImage(img, "png", 150, 5, 33, 10);
      docMXN.addImage(img2, "png", 150, 20, 25, 25);
      docMXN.setFontSize(14)
      docMXN.text(`Estado de Cuenta MXN`,15,25);
      docMXN.text(`Cliente ${nombreCliente}`,15,30);
      docMXN.text(`Desde ${selectedFechaInicio}`,15,35);
    docMXN.autoTable({
      head: [["Fecha", "Movimiento", "Descripcion", "Cargos", "Abonos", "Saldo"]],
      body: dataMXNPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          { content: 'Saldo Anterior', colSpan: 2, styles: { halign: 'left' } },
          // "Saldo Anterior",
          "$" + new Intl.NumberFormat("en-US").format(saldoMXNInicial) + " MXN",
        ],
        [
          "",
          "",
          "",
          { content: 'Cargos del Periodo', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(totalCargosMXNEdoCte) + " MXN",
        ],
        [
          "",
          "",
          "",
          { content: 'Abonos del Periodo', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(totalAbonosMXNEdoCte) + " MXN",
        ],
        [
          "",
          "",
          "",
          { content: 'Saldo Actual', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(saldoMXNParcial) + " MXN",
        ],
      ],
      showFoot: "lastPage",
    });
    docMXN.save(`EdoCtaClienteMXN-${nombreCliente}.pdf`);
  }

  if(movsUSD.length>0){
    const docUSD = new jsPDF();
    docUSD.addImage(img, "png", 150, 5, 33, 10);
    docUSD.addImage(img2, "png", 150, 20, 25, 25);
    docUSD.setFontSize(14)
    docUSD.text(`Estado de Cuenta USD`,15,25);
    docUSD.text(`Cliente ${nombreCliente}`,15,30);
    docUSD.text(`Desde ${selectedFechaInicio}`,15,35);
    docUSD.autoTable({
      head: [["Fecha", "Movimiento", "Descripcion", "Cargos", "Abonos", "Saldo"]],
      body: dataUSDPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          { content: 'Saldo Anterior', colSpan: 2, styles: { halign: 'left' } },
          // "Saldo Anterior",
          "$" + new Intl.NumberFormat("en-US").format(saldoUSDInicial) + " USD",
        ],
        [
          "",
          "",
          "",
          { content: 'Cargos del Periodo', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(totalCargosUSDEdoCte) + " USD",
        ],
        [
          "",
          "",
          "",
          { content: 'Abonos del Periodo', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(totalAbonosUSDEdoCte) + " USD",
        ],
        [
          "",
          "",
          "",
          { content: 'Saldo Actual', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(saldoUSDParcial) + " USD",
        ],
      ],
      showFoot: "lastPage",
    });
    docUSD.save(`EdoCtaClienteUSD-${nombreCliente}.pdf`);
  }
  }


  let totalSaldoMXN = 0
  let totalSaldoUSD = 0

  let saldoMXNParcialTabla = saldoMXNInicial
  let saldoUSDParcialTabla = saldoUSDInicial
  let totalCargosMXNEdo = 0
  let totalCargosUSDEdo = 0
  let totalAbonosMXNEdo = 0
  let totalAbonosUSDEdo = 0


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card container col-sm-11">
          <Row>
            <Col md={8}>
               
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          
          <h3 align="center">Estado de Cuenta</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                totalSaldoMXN = totalSaldoMXN + p.saldoMXN
                totalSaldoUSD = totalSaldoUSD + p.saldoUSD
                {
                  return (
                    <tr>
                      <td> {p.nombre}</td>
                      <td>{"$"+new Intl.NumberFormat("en-US").format(p.saldoMXN)}</td>
                      <td>{"$"+new Intl.NumberFormat("en-US").format(p.saldoUSD)}</td>
                      <td>
                      <Button
                              color="info"
                              size="sm"
                              onClick={(e) => jalaMov(
                                  p._id, 
                                  p.cliente,
                                  p.saldoMXN,
                                  p.saldoUSD
                                )
                              }
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                        
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td className="negrita" align="right">Totales</td>
                <td className="negrita">{"$"+new Intl.NumberFormat("en-US").format(totalSaldoMXN)} MXN</td>
                <td className="negrita">{"$"+new Intl.NumberFormat("en-US").format(totalSaldoUSD)} USD</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          <Col sm={3}>
            <ButtonGroup>
            <Button size="sm" className="btn" color="danger" 
              onClick={PDFOC}
              >
                PDF <i class="far fa-file-pdf"></i>
              </Button>
             
            </ButtonGroup>
          </Col> 

          <Col md={3}>
            <Row>
              <Col md={3}>
              <Label className="mr-sm-2">Fecha</Label>
              </Col>
              <Col md={6}>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
              </Col>
            </Row>

          </Col>


        <Col md={3}>
                <h4 align="right">
                              Saldo{"  "}
                              {saldoMXN > 0?(
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {"$" + new Intl.NumberFormat("en-US").format(saldoMXN)} MXN
                              </Badge>
                              ):undefined}
                              {saldoUSD >0?(
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {"$" + new Intl.NumberFormat("en-US").format(saldoUSD)} USD
                              </Badge>
                              ):undefined}
                            </h4>
                </Col>
                </Row>
                {movsMXN.length >0?( 
          <div className="card container">
            <h4>Movimientos MXN</h4>
          <Table size="sm" className="table-responsive-xl">
                  <thead>
                      <tr align="center">
                        <th className="tituloTabla" align="left">
                          Fecha
                        </th>
                        <th className="tituloTabla">
                          Movimiento
                        </th>
                        <th className="tituloTabla">
                          Numero
                        </th>
                        <th className="tituloTabla">
                          Cargos
                        </th><th className="tituloTabla">
                          Abonos
                        </th>
                        <th className="tituloTabla">
                          Saldo
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        {selectedFechaInicio?(
                              <tr>
                              <td align="center">NA</td>
                              <td align="center">Saldo Anterior</td>
                              <td align="center">NA</td>
                              <td align="center">NA</td>
                              <td align="center">NA</td>
                              <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoMXNInicial)} MXN</td>
                            </tr>
                        ):undefined}
                      {movsMXN
                      .map((c) => {
                        if(
                          (selectedFechaInicio == "" || selectedFechaInicio <= c.fecha)
                          ){
                        saldoMXNParcialTabla = saldoMXNParcialTabla + c.cargo - c.abono
                        totalCargosMXNEdo = totalCargosMXNEdo + c.cargo
                        totalAbonosMXNEdo = totalAbonosMXNEdo + c.abono
                        return (
                          <tr>
                            <td align="center">{c.fecha}</td>
                           <td align="center">{c.movimiento}</td>
                           <td align="center">{c.numero}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.cargo)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.abono)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoMXNParcialTabla)} MXN</td>
                         </tr>
                        )
                      }
                      })}

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Saldo Anterior</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoMXNInicial)} MXN </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Cargos del Periodo</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalCargosMXNEdo)} MXN </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Abonos del Periodo</td>
                        <td td className="negrita" align="center">{"$-" + new Intl.NumberFormat("en-US").format(totalAbonosMXNEdo)} MXN </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Saldo Actual</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoMXNParcialTabla)} MXN </td>
                      </tr>
                    </tbody>
                  </Table>
          </div>
          ):undefined}
          <br />
          <br />
          {movsUSD.length >0?( 
          <div className="card container">
            <h4>Movimientos USD</h4>
          <Table size="sm" className="table-responsive-xl">
                  <thead>
                      <tr align="center">
                        <th className="tituloTabla" align="left">
                          Fecha
                        </th>
                        <th className="tituloTabla">
                          Movimiento
                        </th>
                        <th className="tituloTabla">
                          Numero
                        </th>
                        <th className="tituloTabla">
                          Cargos
                        </th><th className="tituloTabla">
                          Abonos
                        </th>
                        <th className="tituloTabla">
                          Saldo
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        {selectedFechaInicio?(
                              <tr>
                              <td align="center">NA</td>
                              <td align="center">Saldo Anterior</td>
                              <td align="center">NA</td>
                              <td align="center">NA</td>
                              <td align="center">NA</td>
                              <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoUSDInicial)} USD</td>
                            </tr>
                        ):undefined}
                      {movsUSD
                      .map((c) => {
                        if(
                          (selectedFechaInicio == "" || selectedFechaInicio <= c.fecha)
                          ){
                        saldoUSDParcialTabla = saldoUSDParcialTabla + c.cargo - c.abono
                        totalCargosUSDEdo = totalCargosUSDEdo + c.cargo
                        totalAbonosUSDEdo = totalAbonosUSDEdo + c.abono
                        return (
                          <tr>
                            <td align="center">{c.fecha}</td>
                           <td align="center">{c.movimiento}</td>
                           <td align="center">{c.numero}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.cargo)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.abono)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoUSDParcialTabla)} USD</td>
                         </tr>
                        )
                      }
                      })}

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Saldo Anterior</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoUSDInicial)} USD </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Cargos del Periodo</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalCargosUSDEdo)} USD </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Abonos del Periodo</td>
                        <td td className="negrita" align="center">{"$-" + new Intl.NumberFormat("en-US").format(totalAbonosUSDEdo)} USD </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Saldo Actual</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoUSDParcialTabla)} USD </td>
                      </tr>
                    </tbody>
                  </Table>
          </div>
          ):undefined}
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Estado de Cuenta</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="info" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
        
          


      {loader}
    </>
  );
}

export default EstadoCuenta;
