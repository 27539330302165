import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Clientes
import ListadoClientes from "./views/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/ClientesCreate"


// Gastos
import BotonesGastos from "./views/Gastos/BotonesGastos"
import ListadoGastosGeneral from './views/Gastos/GastosGeneral/ListadoGastosGeneral'
import GastosGeneralCreate from './views/Gastos/GastosGeneral/GastosGeneralCreate'
import ListadoConceptosGastos from './views/Gastos/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/Gastos/ConceptosGastos/ConceptosGastosCreate'
import ListadoGastosPropiedaes from "./views/Gastos/GastosPropiedades/ListadoGastosPropiedades.jsx";
import GastosPropiedadesCreate from "./views/Gastos/GastosPropiedades/GastosPropiedaesCreate.jsx";



//Reportes
import BotonesReportes from "./views/Reportes/BotonesReportes";
import GraficaVentasMensual from "./views/Reportes/GraficaVentasMensual";
import GraficaVentasAnual from "./views/Reportes/GraficaVentasAnual";
import GraficaAbonosMensual from "./views/Reportes/GraficaAbonosMensual";

// Retiros
import BotonesRetiros from "./views/Retiros/BotonesRetiros.jsx";
import ListadoSocios from "./views/Retiros/Socios/ListadoSocios.jsx";
import ListadoRetiros from "./views/Retiros/Retiros/ListadoRetiros.jsx";
import RetirosCreate from "./views/Retiros/Retiros/RetirosCreate.jsx";
import ListadoPorRetirar from "./views/Retiros/PorRetirar/ListadoPorRetirar.jsx";
import PorRetirarCreate from "./views/Retiros/PorRetirar/PorRetirarCreate.jsx";

// Inventarios
import BotonesInventarios from "./views/Inventarios/BotonesInventarios.jsx";
import ListadoTipoInmueble from "./views/Inventarios/TipoInmueble/ListadoTipoInmueble.jsx";
import PropiedadesCreate from "./views/Inventarios/Propiedades/PropiedadesCreate.jsx";
import ListadoPropiedades from "./views/Inventarios/Propiedades/ListadoPropiedades.jsx";


// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import ListadoMovimientosBancos from "./views/Admin/Bancos/ListadomovimientosBancos";
import ListadoCargos from "./views/Admin/Cargos/ListadoCargos"
import CargosCreate from "./views/Admin/Cargos/CargosCreate"
import ListadoAbonos from "./views/Admin/Abonos/ListadoAbonos"
import AbonosCreate from "./views/Admin/Abonos/AbonosCreate"
import ListadoRentas from "./views/Admin/Rentas/ListadoRentas"
import RentasCreate from "./views/Admin/Rentas/RentasCreate"
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                 {/* Clientes */}
                 <Route exact path ="/ListadoClientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />

                {/* Gastos */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/Gastos" component={ListadoGastosGeneral} />
                <Route exact path ="/GastosCreate" component={GastosGeneralCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                <Route exact path ="/GastosPropiedades" component={ListadoGastosPropiedaes} />
                <Route exact path ="/GastosPropiedadesCreate" component={GastosPropiedadesCreate} />
                
                 {/* Reportes */}
                 <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/GraficaVentasMensual" component={GraficaVentasMensual} />
                <Route exact path ="/GraficaVentasAnual" component={GraficaVentasAnual} />
                <Route exact path ="/GraficaAbonosMensual" component={GraficaAbonosMensual} />

                {/* Retiros */}
                <Route exact path ="/MenuRetiros" component={BotonesRetiros} />
                <Route exact path ="/Socios" component={ListadoSocios} />
                <Route exact path ="/Retiros" component={ListadoRetiros} />
                <Route exact path ="/RetirosCreate" component={RetirosCreate} />
                <Route exact path ="/PorRetirar" component={ListadoPorRetirar} />
                <Route exact path ="/PorRetirarCreate" component={PorRetirarCreate} />
                
                {/* Inventarios */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/TipoInmueble" component={ListadoTipoInmueble}/>
                <Route exact path ="/PropiedadesCreate" component={PropiedadesCreate} />
                <Route exact path ="/Propiedades" component={ListadoPropiedades} />

                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/MovimientosBancos" component={ListadoMovimientosBancos} />  
                <Route exact path ="/ListadoCargos" component={ListadoCargos} />
                <Route exact path ="/CargosCreate" component={CargosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoRentas" component={ListadoRentas} />
                <Route exact path ="/RentasCreate" component={RentasCreate} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
