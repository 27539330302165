import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import moment from 'moment';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  
  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [formaPago, setFormaPago] = useState("Efectivo");
  const [moneda, setMoneda] = useState("");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("Abono");
  const [total, setTotal] = useState(0);

  const [inputFields, setInputFields] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);



  useMemo(()=>{
    axios
    .get(URL_CLIENTES, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allClientes = response.data;
      setClientes(allClientes);
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(URL_BANCOS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allBancos = response.data;
      setBancos(allBancos);
    })
    .catch((err) => {
      console.log(err);
    });

if(selectedCliente !=""){
    axios
    .get(`${URL_CARGOS}/cliente/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allCargos = response.data
      let arrayTabla = allCargos
        .map((a) => {
          return {
            id: a._id,
            fecha: a.fecha,
            total_general: a.importe,
            total_generalOriginal: a.importe,
            saldo: a.saldo,
            abonos: a.abonos,
            nota: a.idCargo,
            importe: 0,
            clientes: a.clientes[0]._id,
            observaciones: "NA",
            movimiento:"Nota",
            idPropiedad: a.propiedades[0]._id,
            propiedad: a.propiedades[0].nombre,
            moneda: a.moneda
          };
        }).filter(function (el) {
          return el != null;
        });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  }, [selectedCliente]);


const handleChangeInputImporte = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      if(event.target.value <= i.saldo || event.target.value == ""){
        i[event.target.name] = parseFloat(event.target.value)
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El Pago excede el Saldo",
        });
      }
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales();
};

const handleChangeInputCargo = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
        i.total_general = parseFloat(event.target.value)
        i.saldo = parseFloat(event.target.value) - parseFloat(i.abonos)
    }
    return i;
  });
  setInputFields(newInputFields);
};



function ActualizaTotales(){
  let TEfe = inputFields.map((c) => parseFloat(c.importe));
  let TE = TEfe.reduce((t, total, index) => t + total, 0);

  setTotal(TE )
}  



function savePago() {
  Swal.fire({
    title: "Estas seguro?",
    text: "Se registrará el abono",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Enviar!",
  }).then((result) => {
    if (result.isConfirmed) {
      toggleProgreso();
      let totalMails = inputFields.length 
      inputFields.map((a) => {
        if (a.importe > 0) {
          axios
            .post(
              URL_ABONOS,
              {
                fecha,
                importe: a.importe,
                clientes: selectedCliente,
                cargos: a.id,
                formaPago,
                concepto,
                comisionPagada:"No",
                observaciones: a.observaciones,
                propiedades: a.idPropiedad,
                bancos: selectedBanco,
                moneda,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then((data) => {
              axios
              .patch(
                `${URL_CARGOS}/${a.id}`,
                {
                  saldo:a.saldo-a.importe,
                  abonos: a.abonos + a.importe,
                  importe: a.total_general
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )

              if(moneda == "MXN"){
              axios
              .patch(
                `${URL_CLIENTES}EditCargos/${selectedCliente}`,
                {
                  cargosMXN:a.total_general - a.total_generalOriginal,
                  saldoMXN:a.total_general - a.total_generalOriginal,
                  cargosUSD:0,
                  saldoUSD:0
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )} else {
                axios
                .patch(
                  `${URL_CLIENTES}EditCargos/${selectedCliente}`,
                  {
                    cargosMXN:0,
                    saldoMXN:0,
                    cargosUSD:a.total_general - a.total_generalOriginal,
                    saldoUSD:a.total_general - a.total_generalOriginal,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
              }

              axios
              .post(
                `${URL_MOVIMIENTOS_BANCOS}`,
                {
                  fecha,
                  importe: a.importe,
                  tipo:"Ingreso",
                  bancos: selectedBanco,
                  abonos:data.data._id,
                  moneda,
                  tipoCambio:1
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )

              totalMails = totalMails - 1;
              if (totalMails == 0) {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                // footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalMails = totalMails - 1;
        }
      })


    }
  });
}

const handleChangeInput = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
    }
    return i;
  });
  setInputFields(newInputFields);
};


const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });

    const updatedInputFields = inputFields.map((field) => ({
      ...field,
      importe: 0,
      total_general: field.total_generalOriginal
    }));
  
    setInputFields(updatedInputFields);

  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin  ?(
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
              <Col md={2}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente)
                      setSelectedCliente(selectedCliente._id)
                      setSelectedClienteNombre(selectedCliente.nombre_comercial)
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                    <Label className="mr-sm-2">Concepto</Label>
                    <Input
                      type="select"
                      value={concepto}
                      onChange={(e) => {
                        setConcepto(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Concepto</option>
                      <option value="Abono">Abono</option>
                      <option value="Bonificacion">Bonificacion</option>
                    </Input>
                  </Col>

                <Col md={2}>
                    <Label className="mr-sm-2">Forma de Pago</Label>
                    <Input
                      type="select"
                      value={formaPago}
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="">Selecciona una Forma de Pago</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Transfer">Transfer</option>
                      <option value="TDC">TDC</option>
                      <option value="Bonificacion">Bonificacion</option>
                    </Input>
                  </Col>
                
                  <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
               
            
                </Row>
             <Row>
                <Col md={12}>
                <h4 align="right">
                              Total Pago{"  "}
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {"$" + new Intl.NumberFormat("en-US").format(total)}
                              </Badge>
                            </h4>
                </Col>
              </Row>
              <br />
             <Row>
                {/* Tabla Cargos */}
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                  <th>Fecha</th>
                  <th>Propiedad</th>
                  <th>Movimiento</th>
                  <th>Nota</th>
                  <th>Total</th>
                  <th>Abonos</th>
                  <th>Saldo</th>
                  <th>Importe</th>
                  <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                {inputFields.map((a) => {
                    if (a.moneda === moneda) {
                      return (
                        <tr key={a.id}>
                          <td>{a.fecha}</td>
                          <td>{a.propiedad}</td>
                          <td>{a.movimiento}</td>
                          <td>{a.nota}</td>
                          {/* <td>{"$" + new Intl.NumberFormat("en-US").format(a.total_general) + " " + a.moneda}</td> */}
                          <td>
                          <Input
                              bsSize="sm"
                              type="number"
                              name="total_general"
                              value={a.total_general}
                              onChange={(e) => handleChangeInputCargo(a.id, e)}
                            />
                          </td>
                          <td>{"$" + new Intl.NumberFormat("en-US").format(a.abonos)}</td>
                          <td>{"$" + new Intl.NumberFormat("en-US").format(a.saldo)}</td>
                          <td>
                            <Input
                              bsSize="sm"
                              type="number"
                              name="importe"
                              value={a.importe}
                              onChange={(e) => handleChangeInputImporte(a.id, e)}
                            />
                          </td>
                          <td>
                            <Input
                              bsSize="sm"
                              type="text"
                              name="observaciones"
                              value={a.observaciones}
                              onChange={(e) => handleChangeInput(a.id, e)}
                            />
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}


                </tbody>
                <br />
              </Table>
          

            {/* Termina tabla cargos */}

             </Row>
            </FormGroup>
            <br />
            <Row>
            <Button
              className="btn btn-success"
              onClick={savePago}
            > Registrar
            </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
                  <ModalHeader toggle={toggleProgreso}>
                    <h4>Progreso</h4>
                  </ModalHeader>
                  <ModalBody>
                    Estamos enviando los correos, este proceso puede tardar
                    varios minutos.
                    <br />
                    Por favor no cierre ni refresque su navegador.
                    <br />
                    <div className="divPadre2">
                      <div className="divHijo2">
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

               
        </div>
      ): undefined } 
      
    </>
  );
}

export default AbonosCreate;
