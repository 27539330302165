import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesRetiros() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_socios ?(
      <div className="container">
        <br />
        <br />
        <Row >

        <Col md={4} align="center">
          {user.menu_socios ?(
            <Button href="/Socios" className="botonesMenu" color="success">
              <i class="fas fa-users fa-7x"></i>
              <br />
              <br />
              Socios
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-users fa-7x"></i>
          <br />
          <br />
          Socios
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_socios ?(
            <Button href="/PorRetirar" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Por Retirar
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Por Retirar
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_socios ?(
            <Button href="/Retiros" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Retiros
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Retiros
        </Button> }
          </Col>

        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesRetiros;
